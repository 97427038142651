import { type ReactElement } from 'react'
import { ContentstackBulletListItem } from './ContentstackBulletListItem'
import { type BulletListInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { isArrayEmpty } from '@stuller/shared/util/core'
import clsx from 'clsx'

interface ContentstackBulletListProps {
  data?: BulletListInfoFragment | null
  isCentered?: boolean
}

/**
 * Helper function to not render any parent elements when the bullet list is empty
 */
function isBulletListEmpty (data?: BulletListInfoFragment | null): boolean {
  return isArrayEmpty(data?.items)
}

/**
 * The Bullet List consists of a list of items, each preceded by an icon.
 * Icons are used in place of bullets to draw attention and ease understanding of actions, ideas, or concepts.
 * Bulleted Lists with Icons are used to group instructions, a series of steps, or related messaging points.
 */

function ContentstackBulletList ({ data, isCentered = false }: ContentstackBulletListProps): ReactElement | null {
  const {
    appearance,
    default_icon: defaultIcon,
    items
  } = data ?? {}

  if (isBulletListEmpty(data)) {
    return null
  }

  return (
    <section className={clsx(appearance?.text_color != null && `text-${appearance?.text_color}`)}>
      <ul className='list-unstyled mb-n5'>
        {items?.map((item, key) => (
          <ContentstackBulletListItem
            defaultIcon={defaultIcon}
            item={item}
            key={key}
            isCentered={isCentered}
          />
        ))}
      </ul>
    </section>
  )
}

export {
  ContentstackBulletList,
  isBulletListEmpty
}
