import { type ReactElement } from 'react'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import clsx from 'clsx'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackStatisticsCard } from './ContentstackStatisticsCard'
import { isArrayEmpty } from '@stuller/shared/util/core'
import type { StatisticsCardGroup } from './types'
import { useFilterContentstackTargeting, useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'

interface ContentstackStatisticsCardGroupProps {
  data?: StatisticsCardGroup
}

/**
 *
 * The Statistics Card Group is a collection of cards.
 *
 * Each Statistics Card displays a number or statistic relating to asingle topic together in a way that makes it distinct from the surrounding content.
 *
 *  */
function ContentstackStatisticsCardGroup ({ data }: ContentstackStatisticsCardGroupProps): ReactElement | null {
  const {
    title,
    section_header: sectionHeader,
    statistics_cards: statisticsCards,
    rich_text_below_cards: richTextBelowCards,
    appearance,
    targetingConnection
  } = data ?? {}

  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', 'y', appearance?.spacing_y)

  const filteredStatisticsCards = useFilterContentstackTargeting(
    statisticsCards?.map((card) => ({
      item: card,
      targeting: card?.targetingConnection?.edges?.[0]?.node?.targeting
    }))
  )

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isGroupEmpty = isArrayEmpty(filteredStatisticsCards)
  if (!showContent || isGroupEmpty) {
    return null
  }

  const sectionClassNames = clsx(
    'row g-0 mw-xxxl m-auto',
    background.className,
    appearance?.content_position === 'right' && 'justify-content-end'
  )

  const blurClassNames = clsx(
    'bg-glass-blur',
    appearance?.theme === 'dark' ? 'bg-glass-dark' : 'bg-glass-light',
    appearance?.content_position !== 'center' && 'col-lg-6'
  )

  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8',
    spacing?.className,
    appearance?.theme === 'dark' && 'text-white links-white',
    appearance?.rich_text_below_cards_alignment === 'center' && 'text-center',
    appearance?.content_position === 'left' && 'pe-md-6',
    appearance?.content_position === 'right' && 'ps-md-6'
  )

  const rowClassNames = clsx(
    'd-flex',
    appearance?.content_position === 'center' && 'justify-content-center',
    appearance?.content_position === 'left' && 'justify-content-end',
    appearance?.content_position === 'right' && 'justify-content-start'
  )

  const layoutClassNames = clsx(
    'row gy-5',
    appearance?.content_position !== 'center' && 'content-position-side'
  )

  const cardContainerClassNames = clsx(
    appearance?.content_position === 'center' && `col-md-${Math.floor(12 / filteredStatisticsCards.length)} col-sm-6`,
    appearance?.content_position !== 'center' && 'col-sm-6'
  )

  return (
    <section
      className={sectionClassNames}
      id={contentstackTitleToHtmlId(title)}
      data-gtm-click-section='topic'
      data-gtm-click-subsection1={title}
    >
      <div className={blurClassNames}>
        <div className={containerClassNames}>
          <div className={rowClassNames}>
            <div className={layoutClassNames}>
              <ContentstackSectionHeader data={sectionHeader} />
              {filteredStatisticsCards?.map((card, index) => (
                <div className={cardContainerClassNames} key={index}>
                  <ContentstackStatisticsCard data={card} theme={appearance?.theme ?? 'dark'} />
                </div>
              ))}
              {!isRteEmpty(richTextBelowCards?.json) && (
                <ContentstackJsonRte json={richTextBelowCards?.json} />
              )}
            </div>
            <style jsx>{`
              .content-position-side {
                @media (min-width: ${variables.breakpointLg}) {
                  width: calc(calc(${variables.containerMaxWidthXxl} - 20px) / 2)
                }
              }
            `}
            </style>
          </div>
          {spacing?.styles}
        </div>
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackStatisticsCardGroup
}
