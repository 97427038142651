import { type ReactElement } from 'react'
import { type TopicInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackBulletList, isBulletListEmpty } from '../contentstack-bullet-list/ContentstackBulletList'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackIconList, isIconListEmpty } from '../contentstack-icon-list/ContentstackIconList'
import clsx from 'clsx'

interface ContentstackTopicBodyProps {
  data: TopicInfoFragment['body']
  textPosition?: string
  buttonOrientation?: string
}

/**
 * Renders the body text,  bullet list, and buttons inside of a ContentstackTopic component
 * @memberof ContentstackTopic
 */
function ContentstackTopicBody ({ data, textPosition, buttonOrientation }: ContentstackTopicBodyProps): ReactElement | null {
  const {
    bullet_listsConnection: bulletLists,
    icon_listsConnection: iconLists,
    rich_text: richText,
    buttons
  } = data ?? {}

  const textPositionClasses = clsx(
    'row gy-5',
    textPosition === 'center' && 'text-center'
  )
  const buttonClasses = clsx(
    'row gy-3',
    textPosition === 'center' && 'justify-content-center',
    buttonOrientation === 'vertical' && 'flex-column'
  )

  const emptyRichText = isRteEmpty(richText?.json)
  const hasIconLists = (iconLists?.edges?.length ?? 0) > 0
  const hasBulletLists = (bulletLists?.edges?.length ?? 0) > 0
  const hasButtons = (buttons?.length ?? 0) > 0

  if (emptyRichText && !hasIconLists && !hasBulletLists && !hasButtons) {
    return null
  }

  return (
    <div className={textPositionClasses}>
      {!emptyRichText && (
        <div className='col-12'>
          <ContentstackJsonRte json={richText?.json} />
        </div>
      )}
      {iconLists?.edges?.map((edge, index) => !isIconListEmpty(edge?.node) && (
        <div key={index} className='col-12 col-xl'>
          <ContentstackIconList data={edge?.node} isCentered={textPosition === 'center'} />
        </div>
      ))}
      {!hasIconLists && bulletLists?.edges?.map((edge, index) => !isBulletListEmpty(edge?.node) && (
        <div className='col-12 col-xl' key={index}>
          <ContentstackBulletList data={edge?.node} isCentered={textPosition === 'center'} />
        </div>
      ))}
      {hasButtons && (
        <div className='col-12'>
          <div className={buttonClasses}>
            {buttons?.map((button, i) => !isButtonEmpty(button) && (
              <div
                key={i}
                className='col-auto'
              >
                <ContentstackButton
                  data={button}
                  data-gtm-click-subsection2={`${i > 0 ? 'secondary' : 'primary'}-cta`}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export {
  ContentstackTopicBody
}
