import { type ReactElement } from 'react'
import { type SmallShoppingBucketsInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { Card, ProductBadge, type ProductBadgeType } from '@stuller/stullercom/ui'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import Link from 'next/link'
import clsx from 'clsx'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackSmallShoppingBucketProps {
  data: NonNullable<SmallShoppingBucketsInfoFragment['shopping_buckets']>[0]
  title?: string | null
}

/**
 * Component for small shopping buckets on category pages
 */
function ContentstackSmallShoppingBucket ({ data, title }: ContentstackSmallShoppingBucketProps): ReactElement | null {
  const {
    title: bucketTitle,
    header,
    image,
    link,
    appearance,
    targetingConnection
  } = data ?? {}

  // Check for targeting rules.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isHeaderEmpty = isRteEmpty(header?.heading?.json)
  const isImageEmpty = isSimpleImageEmpty(image)
  const isHeaderBadgeEmpty = isStringEmpty(header?.badge)
  if (!showContent || (isHeaderEmpty && isImageEmpty && isHeaderBadgeEmpty)) {
    return null
  }

  const textContainerClassNames = clsx(
    isImageEmpty ? 'col-12 text-center d-flex justify-content-center gap-3' : 'col-8 px-4'
  )
  const badgeClassNames = clsx(
    'text-start',
    !isImageEmpty && 'pb-2'
  )
  const cardImageClassNames = clsx(
    'col-4 h-100 d-flex',
    appearance?.image_padding === true ? 'p-4 align-items-center' : 'p-0 align-items-start'
  )

  return (
    <div className='col-12 col-sm-6 col-lg-4' data-gtm-click-section='bucket' data-gtm-click-subsection1={title}>
      <Card className='container border-gray-700-hover overflow-hidden' style={{ height: 130 }}>
        <div className='row align-items-center h-100'>
          <div className={textContainerClassNames}>
            {!isHeaderBadgeEmpty && (
              <div className={badgeClassNames}>
                <ProductBadge type={header?.badge as ProductBadgeType} data-gtm-click-text={bucketTitle} />
              </div>
            )}
            {!isHeaderEmpty && (
              <h3 className='mb-0 text-start'>
                <Link className='stretched-link fw-normal text-decoration-none text-body' href={link?.href ?? ''} title={link?.title ?? ''}>
                  <ContentstackJsonRte json={header?.heading?.json} excludeOuterTags />
                </Link>
              </h3>
            )}
          </div>
          {!isImageEmpty && (
            <div className={cardImageClassNames} style={{ maxWidth: 200 }}>
              <ContentstackSimpleImage data={image} className='mh-100' />
            </div>
          )}
        </div>
      </Card>
    </div>
  )
}

export {
  ContentstackSmallShoppingBucket
}
