import { Accordion } from '@stuller/stullercom/ui'
import { ContentstackAccordionItem } from './ContentstackAccordionItem'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { type ReactElement } from 'react'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import { useToggleKey } from '@stuller/shared/util/react-hooks'
import clsx from 'clsx'
import { isArrayEmpty, isStringEmpty } from '@stuller/shared/util/core'
import type { AccordionData } from './types'
import { ContentstackAccordionItemMedia } from './ContentstackAccordionItemMedia'

interface ContentstackAccordionProps {
  data?: AccordionData
}

const alignmentMap: Record<string, string> = {
  left: 'justify-content-start',
  center: 'justify-content-center',
  right: 'justify-content-end'
}

/**
 * The Accordion displays a list of headers that can be clicked to reveal or hide associated content.
 *
 * Accordions can be useful for presenting complex content because they allow users to control what information they see.
 *
 * They can be used to display FAQs.
 */
function ContentstackAccordion ({ data }: ContentstackAccordionProps): ReactElement | null {
  const {
    title,
    section_header: sectionHeader,
    accordion_items: accordionItems,
    rich_text_below_accordion: richTextBelowAccordion,
    button,
    appearance,
    targetingConnection
  } = data ?? {}

  const [open, handleToggle] = useToggleKey('0')

  const layout = appearance?.layout ?? 'normal'

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  // Checker classes for conditional rendering
  const hasBottomText = !isRteEmpty(richTextBelowAccordion?.json)
  const hasBottomButton = !isButtonEmpty(button)
  const hasAccordionItems = !isArrayEmpty(accordionItems)

  if (!hasAccordionItems && !hasBottomText && !hasBottomButton) {
    return null
  }

  // Classes
  const sectionClassName = clsx(
    'mw-xxxl m-auto',
    !isStringEmpty(appearance?.background_color) && `bg-${data?.appearance?.background_color}`
  )
  const bottomContentAlignment = (isStringEmpty(appearance?.button_rich_text_below_accordion_alignment)) ? 'left' : appearance.button_rich_text_below_accordion_alignment
  const bottomContentAlignmentClassName = alignmentMap[bottomContentAlignment]
  const bottomTextClassNames = clsx(
    'col-12 d-flex',
    bottomContentAlignmentClassName,
    sectionHeader?.appearance?.text_color === 'white' && 'text-white links-white'
  )
  const buttonClassName = clsx(
    'col-12 d-flex',
    bottomContentAlignmentClassName
  )

  const layoutClassNames = clsx(
    appearance?.layout === 'normal' && 'col-12',
    appearance?.layout === 'pop-out-media' && 'col-md-6'
  )

  const popOutMediaClassNames = clsx(
    'd-none d-md-block',
    layoutClassNames
  )

  const openIndex = parseInt(Array.isArray(open) ? open[0] : (open ?? '-1'))

  return (
    <section className={sectionClassName} id={contentstackTitleToHtmlId(title)} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
      <div className='container-xxxl mw-xxl px-4 py-6 py-md-8'>
        <div className='row flex-column flex-md-row gy-5'>
          <ContentstackSectionHeader data={sectionHeader} />

          {hasAccordionItems && (
            <Accordion
              data-gtm-click-section='accordion'
              open={open}
              onToggle={handleToggle}
              buttonType={appearance?.icon_style === 'plus-minus' ? 'plus-minus' : 'chevron'}
              faqSchema={appearance?.faqs ?? false}
              className={layoutClassNames}
            >
              {accordionItems?.map((accordionItem, index) => (
                <ContentstackAccordionItem
                  data={accordionItem}
                  key={index}
                  index={index}
                  lastItem={index === accordionItems.length - 1}
                  headerColor={appearance?.background_color === 'gray-100' ? 'bg-white' : ''}
                  layout={layout}
                />
              ))}
            </Accordion>
          )}
          {layout === 'pop-out-media' && <ContentstackAccordionItemMedia data={accordionItems?.[openIndex]?.body?.media} className={popOutMediaClassNames} />}

          {hasBottomText && (
            <div className={bottomTextClassNames}>
              <p className='mb-0'>
                <ContentstackJsonRte json={richTextBelowAccordion?.json} excludeOuterTags />
              </p>
            </div>
          )}

          {hasBottomButton && (
            <div className={buttonClassName}>
              <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
            </div>
          )}

        </div>
      </div>
    </section>
  )
}

export {
  ContentstackAccordion
}
