import { clsx } from 'clsx'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { type ProductStoneShape, ProductStoneShapeIconCard, productStoneShapeIconMap } from '@stuller/stullercom/ui'
import { type ReactElement } from 'react'
import { type StoneShapeIconsInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'

interface ContentstackStoneShapeIconsProps {
  data?: StoneShapeIconsInfoFragment | null
}

/**
 * A CMS user should be able to add a group of Stone Shape Icons to a content page
 * The Stone Shape Icons is a collection of Stone Shape Cards that optionally include a link to a page.
 */
function ContentstackStoneShapeIcons ({ data }: ContentstackStoneShapeIconsProps): ReactElement | null {
  const {
    title,
    stone_shapes: stoneShapes,
    appearance,
    header: sectionHeader,
    targetingConnection
  } = data ?? {}

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  const validStoneShapes = stoneShapes?.filter((stoneShape) => (
    stoneShape?.shape != null && stoneShape.shape in productStoneShapeIconMap
  ))

  if (isArrayEmpty(validStoneShapes)) {
    return null
  }

  const sectionClassNames = clsx(
    'mw-xxxl m-auto',
    appearance?.background_color != null && `bg-${appearance.background_color}`
  )

  const iconRowClassNames = clsx(
    'row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-3',
    appearance?.icon_card_alignment === 'center' && 'justify-content-center'
  )

  return (
    <section className={sectionClassNames} id={contentstackTitleToHtmlId(title)} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
      <div className='container-xxxl py-6 py-md-8 px-4 mw-xxl text-center'>
        <ContentstackSectionHeader data={sectionHeader} className='mb-5' />
        <div className={iconRowClassNames} data-gtm-click-section='icon' data-gtm-click-subsection1={title}>
          {validStoneShapes?.map((shape, index) => (
            <div key={index}>
              <ProductStoneShapeIconCard
                shape={shape?.shape as ProductStoneShape}
                href={shape?.link?.href ?? undefined}
                title={shape?.link?.title ?? undefined}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export {
  ContentstackStoneShapeIcons
}
