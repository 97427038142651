import { ContentstackIconListItemIcon } from './ContentstackIconListItemIcon'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { type ReactElement } from 'react'
import { isStringEmpty } from '@stuller/shared/util/core'
import type { IconListDefaultIcon, IconListItem } from './types'
import clsx from 'clsx'

interface ContentstackIconListItemProps {
  defaultIcon?: IconListDefaultIcon
  data: IconListItem
  isCentered?: boolean
}

/**
 * Individual item rendered inside <ContentstackIconList /> component instances
 * @memberof ContentstackIconList
 */
function ContentstackIconListItem ({ defaultIcon, data, isCentered = false }: ContentstackIconListItemProps): ReactElement | null {
  const {
    heading,
    rich_text: richText,
    icon
  } = data ?? {}

  const iconToUse = isStringEmpty(icon?.name) ? defaultIcon : icon

  const hasEmptyHeading = isRteEmpty(heading?.json)
  const hasEmptyRichText = isRteEmpty(richText?.json)
  const hasEmptyIcon = isStringEmpty(iconToUse?.name)

  if (hasEmptyHeading && hasEmptyRichText && hasEmptyIcon) {
    return null
  }

  const listRowClassNames = clsx(
    'row gx-3',
    isCentered && 'justify-content-center'
  )
  const listcolumnClassNames = clsx(
    'mb-n3',
    isCentered ? 'col-auto' : 'col'
  )

  return (
    <li className='mb-5 p-0'>
      <div className={listRowClassNames}>
        {!hasEmptyIcon && (
          <div className='col-auto'>
            <ContentstackIconListItemIcon icon={iconToUse} />
          </div>
        )}
        {(!hasEmptyHeading || !hasEmptyRichText) && (
          <div className={listcolumnClassNames}>
            {!hasEmptyHeading && (
              <h3 className='mb-1'>
                <ContentstackJsonRte json={heading?.json} excludeOuterTags />
              </h3>
            )}
            <ContentstackJsonRte json={richText?.json} />
          </div>
        )}
      </div>
    </li>
  )
}

export {
  ContentstackIconListItem
}
