import { type ReactElement } from 'react'
import { type BlogCardLink } from './types'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { Button } from '@stuller/stullercom/ui'
import { isStringEmpty } from '@stuller/shared/util/core'

interface ContentstackBlogCardLinkProps {
  data: BlogCardLink
}

/**
 * Renders link in a ContentstackBlogCardGroup
 * @memberof ContentstackBlogCardGroup
 */
function ContentstackBlogCardLink ({ data }: ContentstackBlogCardLinkProps): ReactElement | null {
  const {
    text,
    link,
    open_in_new_tab: openInNewTab
  } = data ?? {}

  const linkText = text?.json
  const linkHref = link?.href
  const linkTitle = link?.title

  if (isRteEmpty(linkText) || isStringEmpty(linkHref)) {
    return null
  }

  return (
    <div
      className='col-12'
    >
      <Button
        tag='a'
        href={linkHref}
        color='link'
        className='text-start p-0 stretched-link'
        aria-label={linkTitle ?? ''}
        target={openInNewTab === true ? '_blank' : ''}
        data-gtm-click-subsection2='primary-cta'
      >
        <ContentstackJsonRte json={linkText} excludeOuterTags />
      </Button>
    </div>
  )
}

export {
  ContentstackBlogCardLink
}
