import { useCallback, useEffect, useRef, useState, type ReactElement } from 'react'
import { type ScrollNavInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import clsx from 'clsx'
import { useEventListener } from '@stuller/shared/util/react-hooks'
import { ContentstackScrollNavLink } from './ContentstackScrollNavLink'
import { isArrayEmpty, isStringEmpty } from '@stuller/shared/util/core'
import { useRouter } from 'next/router'
import { ShadowContainer } from '@stuller/stullercom/ui'

interface ContentstackScrollNavProps {
  data?: ScrollNavInfoFragment | null
}

/**
 * Component that renders a sticky navigation bar with links that scroll to specific sections of a content page
 */
function ContentstackScrollNav ({ data }: ContentstackScrollNavProps): ReactElement | null {
  const {
    navigation_links: navigationLinks,
    title,
    appearance
  } = data ?? {}

  const [selectedId, setSelectedId] = useState<string>('')
  const navRef = useRef<HTMLElement>(null)
  const scrollContainer = useRef<HTMLDivElement>(null)
  const router = useRouter()
  const [filteredNavLinks, setFilteredNavLinks] = useState<ScrollNavInfoFragment['navigation_links']>([])

  const isBackgroundColorEmpty = isStringEmpty(appearance?.glass_background_color)
  const navClassNames = clsx(
    'position-relative d-flex align-items-stretch justify-content-center top-0 z-sticky bg-glass-blur mw-xxxl m-auto',
    !isBackgroundColorEmpty ? `bg-glass-${appearance?.glass_background_color}` : 'bg-glass-gray',
    appearance?.static === true ? '' : 'position-lg-sticky'
  )

  const containerClassNames = clsx(
    'container-xxxl d-flex align-items-center mw-xxl h-100'
  )

  const rowClassNames = clsx(
    'd-flex gap-3 flex-shrink-0 flex-row m-auto'
  )

  const onScroll = useCallback(() => {
    if (navigationLinks != null && navRef.current != null) {
      const scrollY = window.scrollY + (navRef.current?.offsetHeight ?? 0)
      for (const navigationLink of navigationLinks) {
        if (isStringEmpty(navigationLink?.link_id)) {
          return
        }
        const section = document.getElementById(navigationLink.link_id)
        if (section != null) {
          const { offsetTop, offsetHeight } = section
          const bottom = offsetTop + offsetHeight
          if (scrollY >= offsetTop && scrollY <= bottom) {
            setSelectedId(navigationLink.link_id)
          }
        }
      }
    }
  }, [navigationLinks])

  const handleClick = useCallback((linkId?: string | null) => {
    if (isStringEmpty(linkId)) {
      return
    }
    const section = document.getElementById(linkId)
    if (section != null) {
      const updatedURL = `${router.asPath.split('#')[0]}#${linkId}`
      window.location.replace(updatedURL)
    }
  }, [router.asPath])

  useEffect(() => {
    let newFilteredNavLinks = null
    if (navigationLinks != null) {
      newFilteredNavLinks = navigationLinks?.filter((link) => !isStringEmpty(link?.link_id) &&
        (document.getElementById(link.link_id) != null || link.link_id.includes('/')))
      setFilteredNavLinks(newFilteredNavLinks)
    }
    if (newFilteredNavLinks != null) {
      for (const link of newFilteredNavLinks) {
        if (!isStringEmpty(link?.link_id)) {
          const section = document.getElementById(link.link_id)
          if (section != null) {
            section.style.scrollMarginTop = `${(navRef.current?.offsetHeight ?? 0) - 1}px`
          }
        }
      }
    }
  }, [navigationLinks])

  useEventListener('scroll', onScroll)

  if (isArrayEmpty(navigationLinks)) {
    return null
  }

  return (
    <nav
      style={{ height: 58 }}
      ref={navRef}
      className={navClassNames}
    >
      <ShadowContainer
        orientation='horizontal'
        ref={scrollContainer}
        className='h-100'
      >
        <div
          className={containerClassNames}
          data-gtm-click-section='scroll-nav'
          data-gtm-click-subsection1={title}
        >
          <div
            className={rowClassNames}
            ref={scrollContainer}
          >
            {filteredNavLinks?.map((link, index) => link != null && (
              <ContentstackScrollNavLink
                key={index}
                onClick={() => { handleClick(link.link_id) }}
                data={link}
                selectedId={selectedId}
              />
            ))}
          </div>
        </div>
      </ShadowContainer>
    </nav>
  )
}

export {
  ContentstackScrollNav
}
