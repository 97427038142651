import { ContentstackLargeShoppingBucket } from './ContentstackLargeShoppingBucket'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { type LargeShoppingBuckets } from './types'
import { type ReactElement } from 'react'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'

interface ContentstackLargeShoppingBucketsProps {
  data?: LargeShoppingBuckets
}

/**
 * Component for rendering a group of ContentstackLargeShoppingBucketHeader components on category pages
 */
function ContentstackLargeShoppingBuckets ({ data }: ContentstackLargeShoppingBucketsProps): ReactElement | null {
  const {
    title,
    appearance,
    shopping_buckets: shoppingBuckets,
    section_header: sectionHeader,
    targetingConnection
  } = data ?? {}

  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', 'y', appearance?.spacing_y)
  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isGroupEmpty = isArrayEmpty(shoppingBuckets)
  if (!showContent || isGroupEmpty) {
    return null
  }

  const sectionClassNames = clsx(
    'mw-xxxl m-auto',
    background.className
  )

  const containerClassNames = clsx(
    'container-xxxl mw-xxl py-8 px-4',
    spacing?.className
  )

  const rowClassNames = clsx(
    'row g-5',
    appearance?.card_alignment === 'center' && 'text-center justify-content-center'
  )

  return (
    <section className={sectionClassNames} id={contentstackTitleToHtmlId(title)} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
      <div className={containerClassNames}>
        <div className={rowClassNames}>
          <ContentstackSectionHeader data={sectionHeader} />
          <div className='col-12'>
            <div className={rowClassNames}>
              {shoppingBuckets?.map((bucket, i) => <ContentstackLargeShoppingBucket data={bucket} title={title} key={i} />)}
            </div>
          </div>
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export { ContentstackLargeShoppingBuckets }
