import { type ReactElement } from 'react'
import { type ShoppableFeatureInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import clsx from 'clsx'

interface ContentstackShoppableFeatureBodyProps {
  data: ShoppableFeatureInfoFragment['body']
  textPosition?: string
  buttonOrientation?: string
}

/**
 * Renders the body text,  bullet list, and buttons inside of a ContentstackShoppableFeature component
 */
function ContentstackShoppableFeatureBody ({ data, textPosition, buttonOrientation }: ContentstackShoppableFeatureBodyProps): ReactElement | null {
  const {
    rich_text: richText,
    button
  } = data ?? {}
  const textPositionClasses = clsx(
    'row gy-5',
    textPosition === 'center' && 'text-center'
  )
  const buttonClasses = clsx(
    'row gy-3',
    textPosition === 'center' && 'justify-content-center',
    buttonOrientation === 'vertical' && 'flex-column'
  )
  const emptyRichText = isRteEmpty(richText?.json)
  const hasButtons = (button?.length ?? 0) > 0

  if (emptyRichText && !hasButtons) {
    return null
  }

  return (
    <div className={textPositionClasses}>
      {!emptyRichText && (
        <div className='col-12'>
          <ContentstackJsonRte excludeOuterTags json={richText?.json} />
        </div>
      )}
      {hasButtons && (
        <div className='col-12'>
          <div className={buttonClasses}>
            {button?.map((button, i) => !isButtonEmpty(button) && (
              <div
                key={i}
                className='col-auto'
              >
                <ContentstackButton
                  data={button}
                  data-gtm-click-subsection2={`${i > 0 ? 'secondary' : 'primary'}-cta`}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export {
  ContentstackShoppableFeatureBody
}
