import { ContentstackDirectoryCard } from './ContentstackDirectoryCard'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { type ReactElement } from 'react'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import type { DirectoryCardGroup } from './types'

interface ContentstackDirectoryCardGroupProps {
  data?: DirectoryCardGroup | null
}

const cardGroupColumns: Record<string, string> = {
  third: 'col-md-6 col-lg-4',
  fourth: 'col-md-4 col-lg-3'
}

/**
 * The Directory Card Group is a collection of cards.
 *
 * Each Card displays a collection of links related to the topic of the card
 */
function ContentstackDirectoryCardGroup ({ data }: ContentstackDirectoryCardGroupProps): ReactElement | null {
  const {
    title,
    section_header: sectionHeader,
    directory_cards: cards,
    appearance,
    targetingConnection
  } = data ?? {}

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  const isGroupEmpty = isArrayEmpty(cards)
  if (!showContent || isGroupEmpty) {
    return null
  }

  const cardWidth = appearance?.card_width ?? 'fourth'
  const columns = cardGroupColumns[cardWidth]

  return (
    <section
      id={contentstackTitleToHtmlId(title)}
      data-gtm-click-section='topic'
      data-gtm-click-subsection1={title}
    >
      <div className='container-xxxl mw-xxl px-4 py-6 py-md-8'>
        <div className='row flex-column flex-md-row gy-5'>
          <ContentstackSectionHeader data={sectionHeader} />
          {cards?.map((data, index) => (
            <ContentstackDirectoryCard
              data={data}
              key={index}
              columnClass={columns}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export {
  ContentstackDirectoryCardGroup
}
