import type { ReactElement, ElementType } from 'react'
import { ContentstackAccordion } from '../contentstack-accordion/ContentstackAccordion'
import { ContentstackBlogCardGroup } from '../contentstack-blog-card-group/ContentstackBlogCardGroup'
import { ContentstackCallUsFeature } from '../contentstack-call-us-feature/ContentstackCallUsFeature'
import { ContentstackCardGroup } from '../contentstack-card-group/ContentstackCardGroup'
import { ContentstackCardWithBackgroundGroup } from '../contentstack-card-with-background-group/ContentstackCardWithBackgroundGroup'
import { ContentstackDirectoryCardGroup } from '../contentstack-directory-card-group/ContentstackDirectoryCardGroup'
import { ContentstackEscapeHatch } from '../contentstack-escape-hatch/ContentstackEscapeHatch'
import { ContentstackExpandableImageGroup } from '../contentstack-expandable-image-group/ContentstackExpandableImageGroup'
import { ContentstackHighlightFeature } from '../contentstack-highlight-feature/ContentstackHighlightFeature'
import { ContentstackLargeShoppingBuckets } from '../contentstack-large-shopping-buckets/ContentstackLargeShoppingBuckets'
import { ContentstackLegacyCmsContent } from '../contentstack-legacy-cms-content/ContentstackLegacyCmsContent'
import { ContentstackMediaBoxGroup } from '../contentstack-media-box-group/ContentstackMediaBoxGroup'
import { ContentstackMediaCalloutGroup } from '../contentstack-media-callout-group/ContentstackMediaCalloutGroup'
import { ContentstackPopOutFeature } from '../contentstack-pop-out-feature/ContentstackPopOutFeature'
import { ContentstackProductCardCarousel } from '../contentstack-product-card-carousel/ContentstackProductCardCarousel'
import { ContentstackPublicationCarousel } from '../contentstack-publication-carousel/ContentstackPublicationCarousel'
import { ContentstackRow } from '../contentstack-row/ContentstackRow'
import { ContentstackSearchBySerialNumber } from '../contentstack-search-by-serial-number/ContentstackSearchBySerialNumber'
import { ContentstackShoppableFeature } from '../contentstack-shoppable-feature/ContentstackShoppableFeature'
import { ContentstackSmallShoppingBuckets } from '../contentstack-small-shopping-buckets/ContentstackSmallShoppingBuckets'
import { ContentstackSocialIconsFeature } from '../contentstack-social-icons-feature/ContentstackSocialIconsFeature'
import { ContentstackSplitTopic } from '../contentstack-split-topic/ContentstackSplitTopic'
import { ContentstackStatisticsCardGroup } from '../contentstack-statistics-card-group/ContentstackStatisticsCardGroup'
import { ContentstackStoneShapeIcons } from '../contentstack-stone-shape-icons/ContentstackStoneShapeIcons'
import { ContentstackTestimonials } from '../contentstack-testimonials/ContentstackTestimonials'
import { ContentstackTopic } from '../contentstack-topic/ContentstackTopic'
import { ContentstackVerticalBackgroundCardGroup } from '../contentstack-vertical-background-card-group/ContentstackVerticalBackgroundCardGroup'
import { ContentstackVerticalMediaBoxGroup } from '../contentstack-vertical-media-box-group/ContentstackVerticalMediaBoxGroup'
import { ContentstackVideoCarousel } from '../contentstack-video-carousel/ContentstackVideoCarousel'

interface ContentstackModularBlocksProps {
  /**
   * The array of modular blocks to render
   */
  modularBlocks: any
  /**
   * Prefix of the typename for the modular blocks (usually the parent name)
   */
  prefix: string
}

interface ComponentInfo {
  /**
   * The component to render for this modular block
   */
  component: ElementType
  /**
   * The field name to use when accessing the data for this modular block
   */
  field: string
}

/**
 * Map of Contentstack modular block types to their corresponding info
 */
const componentMap: Record<string, ComponentInfo> = {
  Accordion: {
    component: ContentstackAccordion,
    field: 'accordion'
  },
  BackgroundCardGroup: {
    component: ContentstackCardWithBackgroundGroup,
    field: 'background_card_group'
  },
  CardWithBackgroundGroup: {
    component: ContentstackCardWithBackgroundGroup,
    field: 'card_with_background_group'
  },
  BlogCardGroup: {
    component: ContentstackBlogCardGroup,
    field: 'blog_card_group'
  },
  CallUsFeature: {
    component: ContentstackCallUsFeature,
    field: 'call_us_feature'
  },
  CardGroup: {
    component: ContentstackCardGroup,
    field: 'card_group'
  },
  DirectoryCardGroup: {
    component: ContentstackDirectoryCardGroup,
    field: 'directory_card_group'
  },
  EscapeHatch: {
    component: ContentstackEscapeHatch,
    field: 'escape_hatch'
  },
  ExpandableImageGroup: {
    component: ContentstackExpandableImageGroup,
    field: 'expandable_image_group'
  },
  HighlightFeature: {
    component: ContentstackHighlightFeature,
    field: 'highlight_feature'
  },
  LargeShoppingBuckets: {
    component: ContentstackLargeShoppingBuckets,
    field: 'large_shopping_buckets'
  },
  LegacyCmsContent: {
    component: ContentstackLegacyCmsContent,
    field: 'legacy_cms_content'
  },
  MediaBoxGroup: {
    component: ContentstackMediaBoxGroup,
    field: 'media_box_group'
  },
  MediaCalloutGroup: {
    component: ContentstackMediaCalloutGroup,
    field: 'media_callout_group'
  },
  PopOutFeature: {
    component: ContentstackPopOutFeature,
    field: 'pop_out_feature'
  },
  ProductCardCarousel: {
    component: ContentstackProductCardCarousel,
    field: 'product_card_carousel'
  },
  PublicationCarousel: {
    component: ContentstackPublicationCarousel,
    field: 'publication_carousel'
  },
  Row: {
    component: ContentstackRow,
    field: 'row'
  },
  SearchBySerialNumber: {
    component: ContentstackSearchBySerialNumber,
    field: 'search_by_serial_number'
  },
  ShoppableFeature: {
    component: ContentstackShoppableFeature,
    field: 'shoppable_feature'
  },
  SmallShoppingBuckets: {
    component: ContentstackSmallShoppingBuckets,
    field: 'small_shopping_buckets'
  },
  SocialIconsFeature: {
    component: ContentstackSocialIconsFeature,
    field: 'social_icons_feature'
  },
  SplitTopic: {
    component: ContentstackSplitTopic,
    field: 'split_topic'
  },
  StatisticsCardGroup: {
    component: ContentstackStatisticsCardGroup,
    field: 'statistics_card_group'
  },
  StoneShapeIcons: {
    component: ContentstackStoneShapeIcons,
    field: 'stone_shape_icons'
  },
  Testimonials: {
    component: ContentstackTestimonials,
    field: 'testimonials'
  },
  Topic: {
    component: ContentstackTopic,
    field: 'topic'
  },
  VerticalBackgroundCardGroup: {
    component: ContentstackVerticalBackgroundCardGroup,
    field: 'vertical_background_card_group'
  },
  VerticalMediaBoxGroup: {
    component: ContentstackVerticalMediaBoxGroup,
    field: 'vertical_media_box_group'
  },
  VideoCarousel: {
    component: ContentstackVideoCarousel,
    field: 'video_carousel'
  }
}

/**
 * ContentstackModularBlocks component renders a list of modular blocks based on the provided page contents.
 * It dynamically selects and renders the appropriate component for each block type.
 */
function ContentstackModularBlocks ({ modularBlocks, prefix }: ContentstackModularBlocksProps): ReactElement | null {
  return (
    <>
      {modularBlocks?.map((component: any, key: number) => {
        const componentInfo = componentMap[component.__typename.replace(prefix, '')]

        if (componentInfo != null) {
          const Component = componentInfo.component
          const data = component[componentInfo.field]?.[`${componentInfo.field}Connection`]?.edges?.[0]?.node

          if (data !== null) {
            return <Component key={key} data={data} />
          }
        }

        return null
      })}
    </>
  )
}

export {
  ContentstackModularBlocks
}
