import { ContentstackIcon } from '../contentstack-icon/ContentstackIcon'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { isStringEmpty } from '@stuller/shared/util/core'
import { type Icon } from './types'
import { type ReactElement } from 'react'
import clsx from 'clsx'

interface ContentstackBulletListItemIconProps {
  icon: Icon
}

/**
 * Icon rendered inside individual <ContentStackBulletListItem /> component(s)
 * @memberof ContentstackBulletListItem
 */

function ContentstackBulletListItemIcon ({ icon }: ContentstackBulletListItemIconProps): ReactElement {
  const cmsIconColor = clsx(!isStringEmpty(icon?.color) && `text-${icon?.color}`)
  const cmsIconName = icon?.name
  const cmsIconWrapperHeight: number = 28
  const cmsIconWrapperWidth: number = 20

  /**
   * Bootstrap classes applied to the circle wrapper
   */
  const wrapperClasses = clsx(
    'd-flex flex-column align-items-center justify-content-center',
    cmsIconColor
  )

  return (
    <div
      className={wrapperClasses}
      style={{
        maxHeight: cmsIconWrapperHeight,
        maxWidth: cmsIconWrapperWidth,
        minHeight: cmsIconWrapperHeight,
        minWidth: cmsIconWrapperWidth
      }}
    >
      <div className='mt-n2'>
        {!isSimpleImageEmpty(icon?.icon_image)
          ? <ContentstackSimpleImage data={icon?.icon_image} />
          : (
            <ContentstackIcon
              icon={cmsIconName}
              size='xl'
              defaultIcon='fas-circle-check'
            />
          )}
      </div>
    </div>
  )
}

export {
  ContentstackBulletListItemIcon
}
