import { type ReactElement } from 'react'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import clsx from 'clsx'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import type { MediaBoxHeader } from './types'

interface ContentstackMediaBoxHeaderProps {
  data: MediaBoxHeader
  /**
   * Determines left or center aligned text
   */
  alignmentClass?: string
}

/**
 * Renders the heading, subheading, and badge of a ContentstackMediaBox
 * @memberof ContentstackMediaGoxGroup
 */
function ContentstackMediaBoxHeader ({ data, alignmentClass }: ContentstackMediaBoxHeaderProps): ReactElement | null {
  const {
    heading,
    subheading,
    heading_image: headingImage
  } = data ?? {}
  const emptyHeadingJson = isRteEmpty(heading?.json)
  const emptySubheadingJson = isRteEmpty(subheading?.json)
  const emptyHeadingImage = isSimpleImageEmpty(headingImage)

  if (emptyHeadingJson && emptySubheadingJson && emptyHeadingImage) {
    return null
  }

  const headerClasses = clsx(
    alignmentClass,
    'col-12 mb-n3'
  )

  return (
    <div className={headerClasses}>
      {!emptyHeadingImage && (
        <div className='mb-3'>
          <ContentstackSimpleImage data={headingImage} />
        </div>
      )}
      {!emptyHeadingJson && (
        <h2>
          <ContentstackJsonRte excludeOuterTags json={heading?.json} />
        </h2>
      )}
      {!emptySubheadingJson && (
        <h3 className='fw-normal mt-n3'>
          <ContentstackJsonRte excludeOuterTags json={subheading?.json} />
        </h3>
      )}
    </div>
  )
}

export {
  ContentstackMediaBoxHeader
}
