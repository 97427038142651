import { type ReactElement } from 'react'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { isArrayEmpty } from '@stuller/shared/util/core'
import clsx from 'clsx'
import type { MediaBoxBody } from './types'

interface ContentstackMediaBoxBodyProps {
  data: MediaBoxBody
  buttonOrientation?: string | null
  title?: string | null
}

/**
 * Renders the body text and buttons inside of a ContentstackMediaBox component
 * @memberof ContentstackMediaGoxGroup
 */
function ContentstackMediaBoxBody ({ data, buttonOrientation, title }: ContentstackMediaBoxBodyProps): ReactElement | null {
  const { rich_text: richText, buttons } = data ?? {}

  const isRichTextEmpty = isRteEmpty(richText?.json)
  const isButtonArrayEmpty = isArrayEmpty(buttons)

  if (isRichTextEmpty && isButtonArrayEmpty) {
    return null
  }
  const bodyClasses = clsx('row gy-3')
  const buttonClasses = clsx(
    'row gx-4 gy-3 justify-content-center justify-content-md-start',
    buttonOrientation === 'horizontal' ? 'flex-row' : 'flex-column'
  )

  return (
    <div className='col-12'>
      <div className={bodyClasses}>
        {!isRichTextEmpty && (
          <div className='col-12 mb-n3'>
            <ContentstackJsonRte json={richText?.json ?? []} />
          </div>
        )}
        {!isButtonArrayEmpty && (
          <div className='col-12'>
            <div className={buttonClasses}>
              {buttons?.map((button, i) => !isButtonEmpty(button) && (
                <div
                  className='col-auto'
                  key={i}
                >
                  <ContentstackButton
                    data={button}
                    data-gtm-click-subsection2={`${i > 0 ? 'secondary' : 'primary'}-cta`}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {
  ContentstackMediaBoxBody
}
