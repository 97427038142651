import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackCardGroupDisplay } from './ContentstackCardGroupDisplay'
import { ContentstackSectionHeader } from '../contentstack-section-header/ContentstackSectionHeader'
import { contentstackTitleToHtmlId } from '../helpers/contentstackTitleToHtmlId'
import { isArrayEmpty } from '@stuller/shared/util/core'
import { type CardGroup } from './types'
import { type ReactElement } from 'react'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { useContentstackSpacing } from '../contentstack-background/useContentstackSpacing'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'

interface ContentstackCardGroupProps {
  data?: CardGroup | null
}

const cardGroupColumns: Record<string, string> = {
  half: 'row-cols-1 row-cols-sm-2',
  third: 'row-cols-1 row-cols-sm-2 row-cols-lg-3',
  fourth: 'row-cols-1 row-cols-sm-2 row-cols-lg-4',
  fifth: 'row-cols-1 row-cols-sm-2 row-cols-lg-5'
}

const alignmentMap: Record<string, string> = {
  left: 'justify-content-start',
  center: 'justify-content-center',
  right: 'justify-content-end'
}

/**
 * The Card Group is a collection of cards.
 *
 * Each Card groups content relating to a single topic together in a way that makes it distinct from the surrounding content.
 */
function ContentstackCardGroup ({ data }: ContentstackCardGroupProps): ReactElement | null {
  const {
    title,
    section_header: sectionHeader,
    cards,
    button,
    appearance,
    targetingConnection
  } = data ?? {}
  const background = useContentstackBackground(appearance?.background)
  const spacing = useContentstackSpacing('padding', 'y', appearance?.spacing_y)
  const layout = appearance?.section_header_position ?? 'top'
  const isGroupEmpty = isArrayEmpty(cards)
  const emptyButton = isButtonEmpty(button)

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)

  if (!showContent || isGroupEmpty) {
    return null
  }

  const sectionClassNames = clsx(
    'mw-xxxl m-auto',
    background.className
  )
  const containerClassNames = clsx(
    'container-xxxl mw-xxl px-4 py-6 py-md-8',
    spacing?.className
  )
  const headerClassNames = clsx(
    'col-12',
    layout === 'left' && 'col-md-3 d-flex flex-wrap align-content-center align-items-center'
  )
  const contentContainerClassNames = clsx(
    'col-12',
    layout === 'left' && 'col-md-9'
  )
  const columnWidthClassNames = clsx(
    (appearance?.card_width != null && cardGroupColumns[appearance.card_width]) ?? ''
  )
  const rowClassNames = clsx(
    'row gy-5',
    appearance?.display_type !== 'carousel' && columnWidthClassNames,
    appearance?.card_alignment === 'center' ? 'justify-content-center' : 'justify-content-start'
  )
  const buttonClassNames = clsx(
    'w-100 d-flex',
    layout === 'left' ? 'mt-4' : ' mt-6',
    (appearance?.button_alignment != null && alignmentMap[appearance.button_alignment]) ?? 'justify-content-start'
  )

  return (
    <section className={sectionClassNames} id={contentstackTitleToHtmlId(title)} data-gtm-click-section='topic' data-gtm-click-subsection1={title}>
      <div className={containerClassNames}>
        <div className='row gy-5'>
          <div className={headerClassNames}>
            <ContentstackSectionHeader data={sectionHeader} />
            {!emptyButton && layout === 'left' && (
              <div className={buttonClassNames}>
                <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
              </div>
            )}
          </div>
          <div className={contentContainerClassNames}>
            <div className={rowClassNames}>
              <ContentstackCardGroupDisplay
                appearance={appearance}
                cards={cards}
                className={columnWidthClassNames}
              />
              {!emptyButton && layout !== 'left' && (
                <div className={buttonClassNames}>
                  <ContentstackButton data={button} data-gtm-click-subsection2='primary-cta' />
                </div>
              )}
            </div>
          </div>
        </div>
        {spacing?.styles}
      </div>
      {background.styles}
    </section>
  )
}

export {
  ContentstackCardGroup
}
