import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { type ReactElement } from 'react'
import { type MediaCalloutGroupInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { Button, type ButtonProps } from '@stuller/stullercom/ui'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'

interface ContentstackMediaCalloutProps {
  mediaCallout?: NonNullable<MediaCalloutGroupInfoFragment['media_callouts']>[0]
  includeBottomBorder?: boolean
}

/**
 * Individual Media Callout rendered inside <ContentstackMediaCalloutGroup /> component instances
 * @memberof ContentstackMediaCalloutGroup
 */
function ContentstackMediaCallout ({ mediaCallout, includeBottomBorder = false }: ContentstackMediaCalloutProps): ReactElement | null {
  const {
    title,
    link,
    heading,
    rich_text: richText,
    targetingConnection
  } = mediaCallout ?? {}

  const {
    href: linkHref,
    title: linkTitle
  } = link?.link ?? {}
  const linkText = link?.text

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent) {
    return null
  }

  const isHeadingEmpty = isRteEmpty(heading?.json)
  const isRichTextEmpty = isRteEmpty(richText?.json)
  const isLinkTextEmpty = isRteEmpty(linkText?.json)
  const isMediaEmpty = isSimpleImageEmpty(mediaCallout?.simple_image)

  if (isHeadingEmpty && isRichTextEmpty && isLinkTextEmpty && isMediaEmpty) {
    return null
  }

  const containerClassNames = clsx(
    'container mw-100',
    includeBottomBorder ? 'border-bottom pb-5 pb-lg-0 border-bottom-lg-0' : ''
  )

  return (
    <div className='col-12 col-lg-4' data-gtm-click-section='card' data-gtm-click-subsection1={title}>
      <div className={containerClassNames}>
        <div className='row'>
          {!isMediaEmpty && (
            <div
              className='d-flex justify-content-center p-0 rounded-3 overflow-hidden'
              style={{
                height: '125px',
                width: '125px',
                minWidth: '125px'
              }}
            >
              <ContentstackSimpleImage
                data={mediaCallout?.simple_image}
                className='object-fit-contain h-100'
              />
            </div>
          )}
          <div className='col ps-5 pe-0'>
            {!isHeadingEmpty && (
              <h3 className='mb-2'>
                <ContentstackJsonRte json={heading?.json} excludeOuterTags />
              </h3>
            )}
            {!isRichTextEmpty && (
              <p className='mb-2'>
                <ContentstackJsonRte json={richText?.json} excludeOuterTags />
              </p>
            )}
            {!isLinkTextEmpty && (
              <Button
                href={linkHref ?? ''}
                tag='a'
                color={'link' as ButtonProps['color']}
                className='text-start p-0'
                aria-label={linkTitle ?? ''}
                data-gtm-click-subsection2='primary-cta'
              >
                <ContentstackJsonRte json={linkText?.json} excludeOuterTags />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {
  ContentstackMediaCallout
}
