import { Component, type ErrorInfo, type ReactNode } from 'react'
import { logger } from '@stuller/stullercom/feat/datadog-logs'

interface ErrorBoundaryProps {
  /**
   * Children of element
   */
  children?: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

/**
 * Error boundary for Contentstack escape hatch
 */
class ContentstackEscapeHatchErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false
  }

  public static getDerivedStateFromError (_: Error): ErrorBoundaryState {
    return { hasError: true }
  }

  public componentDidCatch (error: Error, errorInfo: ErrorInfo): void {
    logger.error('ContentstackEscapeHatch error', { ...errorInfo }, error)
  }

  public render (): ReactNode | null {
    const { children } = this.props
    const { hasError } = this.state
    if (hasError) {
      return null
    }

    return children
  }
}

export {
  ContentstackEscapeHatchErrorBoundary
}
